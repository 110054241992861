<script>
import BaseImage from '../components/BaseImage.vue'
export default {
  name: 'HomePage',
  components: {
    BaseImage
  },
  page: {
    title: 'Αρχική'
  }
}
</script>

<template>
  <div class="main-banner">
    <b-container class="bv-example-row">
      <b-row>
        <b-col sm="12">
          <BaseImage class="img-fluid easy-escape-logo" src="img/logo.png"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <h1 class="main-banner-text">
            Εκπαιδευτικές Ευέλικτες Ψηφιακές Παιγνιώδεις Δραστηριότητες Απόδρασης με
            Άξονα τον πολιτισμό και Διαθεματική Προσέγγιση που Βασίζονται στο STEAM
          </h1>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
@use './../assets/scss/abstracts' as *;

.main-banner {
  padding: $s-xl 0;
  text-align: center;
  background: $white;
}

.main-banner-text {
  font-family: "Waiting for the Sunrise", cursive;
  font-size: $s-xl;
  padding: $s-l 0;
  color: $red;
}

.easy-escape-logo {
  max-width: 40%;
}
</style>
